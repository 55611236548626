import React from "react"
import SEO from "../components/seo";
import { ColorThemeContextProvider } from "../contexts/colorThemeContext";
import  Hero from '../images/404/Homepage_header.jpeg';
import CCLogoWhite from "../images/Claritycoat AF new.svg";
import FullPageCta from "../components/full-page/full-page-cta";
import {defaultLanguage} from "../../prismic-configuration";
import Layout from "../components/full-page/layout";
import {graphql} from "gatsby";
import {withPrismicPreview} from "gatsby-plugin-prismic-previews";

const NotFound = ({ data }) => {
    const slice = {
        primary: {
            heading: {
                text: 'Page Not Found',
            },
            subheading: {
                html: '',
            },
            image: {
                url: Hero,
            },
            image_mobile: {
                url: Hero,
            },
            video_url: {
                url: null,
            },
            video_mobile_url: {
                url: null,
            },
            video_autoplay: false,
            video_muted: false,
            video_loop: false,
            video_playsinline: false,
            video_controls: false,
            theme: 'dark',
            // cta_1_title: 'Scoll for more',
            // cta_1_link: {
            //     url: null,
            // }
        }
    };

    return (
      <>
          <SEO title="Page Not Found" />
          <ColorThemeContextProvider>
              <Layout
                disappearHeader={true}
                initColorTheme={'dark'}
                logo={ CCLogoWhite }
                topNav={data.prismicHome?.data.menu_links}
                footerNav={data.prismicHome?.data.footer_links}
                currentLanguage={defaultLanguage}
                blogListing={data.allPrismicBlogPost?.nodes}
              >
                  <FullPageCta slice={slice} />
              </Layout>
          </ColorThemeContextProvider>
      </>
    )
};

export const query = graphql`
query NotFoundQuery($lang: String) {
  prismicHome(lang: {eq: $lang}) {
    data {
      menu_links {
          menu_link {
              url
              type
          }
          name {
            text
          }
      }
      footer_links {
        footer_link {
         url
         type
        }
        name {
          text
        }
      }
    }
  }
  
  allPrismicBlogPost(filter: {lang: {eq: $lang}}) {
    nodes {
      url
      data {
        title {
            text
        }
      }
    }
  }
}
`

export default withPrismicPreview(NotFound);